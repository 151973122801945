body {
    font-family: BlinkMacSystemFont, Helvetica, Arial, sans-serif;
    margin: 0;
}

h1 {
    background: #f6f8fa;
    border-bottom: 1px solid #d0d7de;
    color: #888;
    font-size: 1em;
    font-weight: normal;
    margin-top: 0;
    padding: 1rem;
}

h1 .product-owner {
    color: #000;
}

h1 .product-name {
    color: #000;
    font-weight: bold;
}

h2 {
    font-size: 1.3em;
    font-weight: normal;
    padding: 0 1rem;
}

p {
    padding: 0 1rem;
}

ul {
    padding-left: 0;
}

li {
    border-bottom: 1px solid #ddd;
    list-style: none;
    padding: 1rem 0;
}

li time {
    font-family: Liberation Mono, monospace;
    padding: 1rem;
}

[role=alert] {
    color: #d1242f;
}

input[type=text],
input[type=password],
button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 2px;
    border: 1px solid #767676;
    color: #000;
    font-weight: normal;
    padding: 0.5rem;
}

button {
    background: #efefef;
    font-size: 1rem;
}

button:hover {
    background: #f6f8fa;
}

button:disabled {
    background: #efefef4c;
    border-color: #7676764c;
    color: #1010104c;
}

form.signup {
    width: fit-content;
}

form.signup label,
form.signup input[type=text],
form.signup input[type=password],
form.signup button {
    font-family: Liberation Mono, monospace;
}

form.signup label {
    margin-right: 1rem;
}

form.signup input[type=text],
form.signup input[type=password] {
    width: 15rem;
}

form.signup button {
    padding: 0.5rem 0;
    width: 100%;
}
